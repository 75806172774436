var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { noop } from "../services/utils";
import { UNDEFINED_CHECKER_RESULT } from "../constants";
import { EditorView } from "codemirror";
import { HighlightStyle, syntaxHighlighting } from "@codemirror/language";
import { tags } from "@lezer/highlight";
import { javascript } from "@codemirror/lang-javascript";
/**
 * JAVASCRIPT SUPPORT IS CURRENTLY IN BETA
 */
var checkerResult = undefined;
var globalWindow = window;
var windowProxyHandler = {
    get: function (target, prop, receiver) {
        if (!["prompt", "alert"].includes(prop)) {
            // @ts-ignore
            return globalWindow[prop];
        }
        return Reflect.get(target, prop, receiver);
    }
};
var IsaacError = /** @class */ (function (_super) {
    __extends(IsaacError, _super);
    function IsaacError(message) {
        var _this = _super.call(this, message) || this;
        _this.name = "IsaacError"; // (different names for different built-in error classes)
        return _this;
    }
    return IsaacError;
}(Error));
// Run a snippet of javascript code
var runCode = function (code, printOutput, handleInput, shouldStopExecution, options, testCallbacks) {
    if (options === void 0) { options = {}; }
    return new Promise(function (resolve, reject) {
        var finalOutput = "";
        var outputSinceLastTest = "";
        function startTest(inputs, regex) {
            outputSinceLastTest = "";
            var args = arguments.length;
            if (undefined === testCallbacks) {
                return; // since the setup code is bundled with the students code, we need to allow this to be run even if not testing
            }
            if (0 > args || args > 2) {
                throw new IsaacError("startTest takes two arguments, a list of input strings and a regex string - either can also be set to undefined");
            }
            testCallbacks.setTestInputs(args < 1 ? undefined : (function (is) {
                if (Array.isArray(is) || undefined === is) {
                    return is;
                }
                else {
                    throw new IsaacError("Test inputs must be a list or undefined");
                }
            })(inputs));
            testCallbacks.setTestRegex(args < 2 ? undefined : (function (re) {
                if (typeof re === "string" || undefined === re) {
                    return re;
                }
                else {
                    throw new IsaacError("Regex must be a string or undefined");
                }
            })(regex));
        }
        function getTestOutput() {
            if (undefined === testCallbacks) {
                throw new IsaacError("name 'getTestOutput' is not defined - nice try!");
            }
            return outputSinceLastTest;
        }
        function endTest(testSuccess, testFail, allInputsMustBeUsed) {
            var args = arguments.length;
            if (undefined === testCallbacks) {
                throw new IsaacError("name 'endTest' is not defined - nice try!");
            }
            if (0 > args || args > 3) {
                throw new IsaacError("endTest takes three arguments. These are two message strings - one to show on test pass and " +
                    "one to show on test fail, and the third is a boolean deciding whether all test inputs given need to " +
                    "be used or not. The first two arguments can also be set to undefined.");
            }
            var successMessage = args < 1 ? undefined : (function (message) {
                if (typeof message === "string" || undefined === message) {
                    return message;
                }
                else {
                    throw new IsaacError("'Test success' feedback must be a string or undefined");
                }
            })(testSuccess);
            var failMessage = args < 2 ? undefined : (function (message) {
                if (typeof message === "string" || undefined === message) {
                    return message;
                }
                else {
                    throw new IsaacError("'Test failed' feedback must be a string or undefined");
                }
            })(testFail);
            var useAllInputs = args < 3 ? undefined : (function (uai) {
                if (typeof uai === "boolean" || undefined === uai) {
                    return uai;
                }
                else {
                    throw new IsaacError("'allInputsMustBeUsed' must be a boolean or undefined");
                }
            })(allInputsMustBeUsed);
            // Run test - if the test fails, an error is thrown.
            var error = testCallbacks.runCurrentTest(outputSinceLastTest, useAllInputs, successMessage, failMessage);
            if (error) {
                throw error;
            }
        }
        function promptFunc(promptText, defaultText) {
            var _a;
            if (defaultText !== undefined) {
                throw new IsaacError("Sorry, the Isaac implementation of `prompt` doesn't support default text.");
            }
            // If the input from `handleInput` is just a string, then we can use it.
            var input = handleInput();
            console.log(input);
            if (typeof input == "string") {
                return input;
            }
            else {
                return (_a = globalWindow.prompt(promptText)) !== null && _a !== void 0 ? _a : "";
            }
        }
        function alertFunc(message) {
            printOutput(message.toString());
            finalOutput += message;
            outputSinceLastTest += message;
        }
        // Shadow globally scoped names that we want to override.
        // Using proxies to redirect un-shadowed window and console commands to the actual global objects
        var window = new Proxy({
            prompt: promptFunc,
            alert: alertFunc
        }, windowProxyHandler);
        var prompt = promptFunc;
        var alert = alertFunc;
        // TODO allow stopping execution with shouldStopExecution
        return (function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                eval(code);
                return [2 /*return*/];
            });
        }); })().then(function () {
            resolve(finalOutput);
        }).catch(function (err) {
            if (err instanceof Error) {
                switch (err.name) {
                    case "IsaacError":
                        reject({ error: err.message, isContentError: true });
                        break;
                    case "TestError":
                        reject({ error: err.message, isTestError: true });
                        break;
                    default:
                        reject({ error: err.toString() });
                }
            }
            else if (err.hasOwnProperty("error")) {
                reject(err);
            }
            else {
                reject({ error: err.toString() });
            }
        });
    });
};
function runSetupCode(printOutput, handleInput, setupCode, testCallbacks) {
    if (setupCode) {
        return runCode(setupCode, printOutput, handleInput, function () { return false; }, {}, testCallbacks);
    }
    else {
        return new Promise(function (resolve) { return resolve(""); });
    }
}
function runTests(output, handleInput, shouldStopExecution, testCode, testCallbacks) {
    if (testCode) {
        return runCode(testCode, noop, handleInput, shouldStopExecution, {}, testCallbacks).then(function (testOutput) {
            // Do something with output + testOutput maybe?
            return checkerResult !== null && checkerResult !== void 0 ? checkerResult : UNDEFINED_CHECKER_RESULT;
        });
    }
    else {
        return new Promise(function () { return UNDEFINED_CHECKER_RESULT; });
    }
}
export var javaScriptLanguage = {
    runCode: runCode,
    runSetupCode: runSetupCode,
    runTests: runTests,
    wrapInMain: function (code, doChecks) { return "function main() {\n" + code.split("\n").map(function (s) { return "\t" + s; }).join("\n") + "\n}\n" + (!doChecks ? "main()\n" : ""); },
    testingLibrary: "\nfunction arraysEqual(a, b) {\n  if (a === b) return true;\n  if (a == null || b == null) return false;\n  if (a.length !== b.length) return false;\n  for (var i = 0; i < a.length; ++i) {\n    if (a[i] !== b[i]) return false;\n  }\n  return true;\n}\n\nclass TestError extends Error {\n  constructor(message) {\n    super(message);\n  }\n}\n        ",
    requiresBundledCode: true,
    syncTestInputHander: true,
};
// --- JavaScript theme ---
export var javaScriptTheme = EditorView.theme({
    ".cm-gutters": {
        backgroundColor: "#FFFFFF"
    },
    ".cm-line": {
        paddingLeft: "7px"
    },
    "&": {
        color: "#545454"
    },
});
export var javaScriptHighlightStyle = HighlightStyle.define([
    { tag: tags.docString, color: "#008000" },
    { tag: tags.comment, color: "#696969" },
    { tag: tags.definitionKeyword, color: "#007faa" },
    { tag: tags.function(tags.definition(tags.variableName)), color: "#007faa" },
    { tag: tags.keyword, color: "#7928a1" },
    { tag: tags.number, color: "#aa5d00" },
    { tag: tags.bool, color: "#aa5d00" },
    { tag: tags.lineComment, color: "#696969" },
    { tag: tags.string, color: "#008000" },
]);
export var javaScriptCodeMirrorTheme = {
    languageSupport: javascript(),
    theme: javaScriptTheme,
    highlightStyle: syntaxHighlighting(javaScriptHighlightStyle),
};
