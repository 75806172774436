var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { noop } from "../services/utils";
import { ERRORS, UNDEFINED_CHECKER_RESULT } from "../constants";
import { python } from "@codemirror/lang-python";
import { EditorView } from "codemirror";
import { HighlightStyle, syntaxHighlighting } from "@codemirror/language";
import { tags } from "@lezer/highlight";
var setupSkulpt = function () { return __awaiter(void 0, void 0, void 0, function () {
    var skulpt;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, import("skulpt")];
            case 1:
                skulpt = (_a.sent()).default;
                return [2 /*return*/, skulpt];
        }
    });
}); };
// Transpile and run a snippet of python code
var runCode = function (code, printOutput, handleInput, shouldStopExecution, skulptOptions, testCallbacks, initOutputSinceLastTest) {
    if (skulptOptions === void 0) { skulptOptions = {}; }
    if (initOutputSinceLastTest === void 0) { initOutputSinceLastTest = ""; }
    return new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
        function startTest(inputs, regex) {
            outputSinceLastTest = "";
            var args = arguments.length;
            if (undefined === testCallbacks) {
                throw new Sk.builtin.NameError("name 'startTest' is not defined - nice try!");
            }
            if (0 > args || args > 2) {
                throw { error: "startTest takes two arguments, a list of input strings and a regex string - either can also be set to None" };
            }
            testCallbacks.setTestInputs(args < 1 ? undefined : (function (is) {
                if (is instanceof Sk.builtin.list || is instanceof Sk.builtin.tuple) {
                    return Sk.ffi.remapToJs(is);
                }
                else if (Sk.builtin.checkNone(is)) {
                    return undefined;
                }
                else {
                    throw { error: "Test inputs must be a list-like object or None" };
                }
            })(inputs));
            testCallbacks.setTestRegex(args < 2 ? undefined : (function (re) {
                if (Sk.builtin.checkString(re)) {
                    return Sk.ffi.remapToJs(re);
                }
                else if (Sk.builtin.checkNone(re)) {
                    return undefined;
                }
                else {
                    throw { error: "Regex must be a string or None" };
                }
            })(regex));
        }
        function getTestOutput() {
            if (undefined === testCallbacks) {
                throw new Sk.builtin.NameError("name 'getTestOutput' is not defined - nice try!");
            }
            return Sk.builtins.str(outputSinceLastTest);
        }
        function endTest(testSuccess, testFail, allInputsMustBeUsed) {
            var args = arguments.length;
            if (undefined === testCallbacks) {
                throw new Sk.builtin.NameError("name 'endTest' is not defined - nice try!");
            }
            if (0 > args || args > 3) {
                throw { error: "endTest takes three arguments. These are two message strings - one to show on test pass and " +
                        "one to show on test fail, and the third is a boolean deciding whether all test inputs given need to " +
                        "be used or not. The first two arguments can also be set to None." };
            }
            var successMessage = args < 1 ? undefined : (function (message) {
                if (Sk.builtin.checkString(message)) {
                    return Sk.ffi.remapToJs(message);
                }
                else if (Sk.builtin.checkNone(message)) {
                    return undefined;
                }
                else {
                    throw { error: "'Test success' feedback must be a string or None" };
                }
            })(testSuccess);
            var failMessage = args < 2 ? undefined : (function (message) {
                if (Sk.builtin.checkString(message)) {
                    return Sk.ffi.remapToJs(message);
                }
                else if (Sk.builtin.checkNone(message)) {
                    return undefined;
                }
                else {
                    throw { error: "'Test failed' feedback must be a string or None" };
                }
            })(testFail);
            var useAllInputs = args < 3 ? undefined : (function (uai) {
                if (Sk.builtin.checkBool(uai)) {
                    return Sk.ffi.remapToJs(uai);
                }
                else if (Sk.builtin.checkNone(uai)) {
                    return undefined;
                }
                else {
                    throw { error: "'allInputsMustBeUsed' must be a boolean or None" };
                }
            })(allInputsMustBeUsed);
            // Run test - if the test fails, an error is thrown
            var error = testCallbacks.runCurrentTest(outputSinceLastTest, useAllInputs, successMessage, failMessage);
            if (error) {
                throw error;
            }
        }
        function builtinRead(x) {
            if (Sk.builtinFiles === undefined || Sk.builtinFiles["files"][x] === undefined)
                throw new Error("File not found: '" + x + "'");
            return Sk.builtinFiles["files"][x];
        }
        var Sk, finalOutput, outputSinceLastTest;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, setupSkulpt()];
                case 1:
                    Sk = _a.sent();
                    finalOutput = "";
                    outputSinceLastTest = initOutputSinceLastTest;
                    // Make the custom test functions available in Python
                    Sk.builtins = __assign(__assign({}, Sk.builtins), { "startTest": startTest, "endTest": endTest, "getTestOutput": getTestOutput });
                    Sk.configure(__assign({ output: function (output) {
                            finalOutput += output;
                            outputSinceLastTest += output;
                            printOutput(output);
                        }, inputfun: function () { return new Promise(function (resolve, reject) {
                            var inputStartTime = Date.now();
                            var input = handleInput();
                            if (typeof input === "string") {
                                return function () { return resolve(input); };
                            }
                            else {
                                return input.then(function (input) {
                                    if (Sk.execLimit) {
                                        Sk.execLimit = Sk.execLimit + (Date.now() - inputStartTime);
                                    }
                                    resolve(input);
                                }).catch(reject);
                            }
                        }); }, yieldLimit: 100, read: builtinRead, killableWhile: true, killableFor: true, __future__: Sk.python3 }, skulptOptions));
                    return [2 /*return*/, Sk.misceval.asyncToPromise(function () { return Sk.importMainWithBody("<stdin>", false, code, true); }, {
                            // https://stackoverflow.com/questions/54503455/how-to-stop-a-script-in-skulpt
                            "*": function () {
                                if (shouldStopExecution(true))
                                    throw "Execution interrupted";
                            }
                        }).then(function () {
                            resolve(finalOutput);
                        }).catch(function (err) {
                            if (typeof err === "object" && "nativeError" in err && err.nativeError === ERRORS.EXEC_STOP_ERROR) {
                                reject({ error: "Execution interrupted" });
                                return;
                            }
                            switch (err.tp$name) {
                                case ERRORS.TIME_LIMIT_ERROR:
                                    reject({ error: "Your program took too long to execute! Are there any infinite loops?" });
                                    break;
                                case ERRORS.EXTERNAL_ERROR:
                                    reject({ error: err.nativeError.error, isTestError: err.nativeError.isTestError });
                                    break;
                                case ERRORS.TEST_ERROR:
                                    reject({ error: err.toString().slice(11), isTestError: true });
                                    break;
                                default:
                                    reject({ error: err.toString() });
                            }
                        })];
            }
        });
    }); });
};
function runSetupCode(printOutput, handleInput, setupCode, testCallbacks) {
    if (setupCode) {
        return runCode(setupCode, printOutput, handleInput, function () { return false; }, { retainGlobals: true, execLimit: 3000 /* setup code can only take a maximum of 3 seconds */ }, testCallbacks);
    }
    else {
        return new Promise(function (resolve) { return resolve(""); });
    }
}
function runTests(output, handleInput, shouldStopExecution, testCode, testCallbacks) {
    return __awaiter(this, void 0, void 0, function () {
        var Sk;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, setupSkulpt()];
                case 1:
                    Sk = _a.sent();
                    if (testCode) {
                        return [2 /*return*/, runCode(testCode, noop, handleInput, shouldStopExecution, { retainGlobals: true }, testCallbacks, output).then(function (testOutput) {
                                var _a, _b, _c;
                                // Do something with output + testOutput maybe?
                                return (_c = (_b = (_a = Sk.globals["checkerResult"]) === null || _a === void 0 ? void 0 : _a.v) === null || _b === void 0 ? void 0 : _b.toString()) !== null && _c !== void 0 ? _c : UNDEFINED_CHECKER_RESULT;
                            })];
                    }
                    else {
                        return [2 /*return*/, new Promise(function () { return UNDEFINED_CHECKER_RESULT; })];
                    }
                    return [2 /*return*/];
            }
        });
    });
}
export var pythonLanguage = {
    runCode: runCode,
    runSetupCode: runSetupCode,
    runTests: runTests,
    wrapInMain: function (code, doChecks) { return "def main():\n" + code.split("\n").map(function (s) { return "\t" + s; }).join("\n") + (!doChecks ? "\nmain()\n" : ""); },
    testingLibrary: "\nclass TestError(Exception):\n  pass",
    requiresBundledCode: false,
    syncTestInputHander: false,
};
// --- Python theme ---
export var pythonTheme = EditorView.theme({
    ".cm-gutters": {
        backgroundColor: "#FFFFFF"
    },
    ".cm-line": {
        paddingLeft: "7px"
    },
    "&": {
        color: "#545454"
    },
});
export var pythonHighlightStyle = HighlightStyle.define([
    { tag: tags.docString, color: "#008000" },
    { tag: tags.comment, color: "#696969" },
    { tag: tags.definitionKeyword, color: "#7928a1" },
    { tag: tags.function(tags.definition(tags.variableName)), color: "#007faa" },
    { tag: tags.keyword, color: "#7928a1" },
    { tag: tags.number, color: "#aa5d00" },
    { tag: tags.bool, color: "#aa5d00" },
    { tag: tags.lineComment, color: "#696969" },
    { tag: tags.string, color: "#008000" },
]);
export var pythonCodeMirrorTheme = {
    languageSupport: python(),
    theme: pythonTheme,
    highlightStyle: syntaxHighlighting(pythonHighlightStyle),
};
