var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { basicSetup } from "codemirror";
import { EditorView, keymap } from "@codemirror/view";
import { EditorState, Transaction } from "@codemirror/state";
import { history, indentWithTab } from "@codemirror/commands";
import { THEMES } from "./constants";
import { pythonCodeMirrorTheme } from "./languages/python";
import { isDefined } from "./services/utils";
export var Editor = React.forwardRef(function (_a, ref) {
    var initCode = _a.initCode, language = _a.language, appendToChangeLog = _a.appendToChangeLog;
    var _b = useState(null), editor = _b[0], setEditor = _b[1];
    var editorRef = useRef(null);
    // Expose editor.state.doc.toString() to the parent component
    useImperativeHandle(ref, function () { return ({
        getCode: function () { return editor ? editor.state.doc.toString() : undefined; },
        setHeight: function (height) {
            var _a;
            (_a = editorRef.current) === null || _a === void 0 ? void 0 : _a.style.setProperty("height", "".concat(height, "px"));
        }
    }); }, [editor]);
    // Insert editor on initCode change
    useEffect(function () {
        var _a;
        // Make sure the forwarded ref is actually a ref object (to appease TS)
        if (editorRef === null || typeof editorRef === "function")
            return;
        // Prevent two editors from appearing when re-rendering
        if (editor) {
            editor.destroy();
        }
        var codeMirrorTheme = (_a = (language ? THEMES.get(language) : undefined)) !== null && _a !== void 0 ? _a : pythonCodeMirrorTheme;
        var codeMirrorThemeExtensions = Object.values(codeMirrorTheme).filter(isDefined);
        setEditor(new EditorView({
            state: EditorState.create({
                doc: initCode,
                extensions: __spreadArray(__spreadArray([], codeMirrorThemeExtensions, true), [
                    basicSetup,
                    keymap.of([indentWithTab]), // about accessibility: https://codemirror.net/6/examples/tab/
                    history(),
                    EditorView.updateListener.of(function (v) {
                        var _a, _b;
                        var annotations = (_a = v.transactions) === null || _a === void 0 ? void 0 : _a.map(function (t) { return t.annotation(Transaction.userEvent); }).filter(isDefined);
                        // A change JSON representation consists of a list of numbers and pairs. The numbers represent
                        // unchanged portions of the document, and the pairs represent additions/deletions within the
                        // document. Each value represents a section of the document, for example:
                        //
                        //  [456, [0, "egg"], 45] - characters 0-455 were unchanged, "egg" was added at index 456,
                        //  the next 45 characters after "egg" were unchanged.
                        //
                        //  [[6, "ham"], 60, [6, "ham"], 10] - first 6 characters were deleted and replaced with "ham",
                        //  the next 60 characters were unchanged, 6 characters after that were deleted and replaced with
                        //  "ham", and the next 10 characters were unchanged
                        var changes = (_b = v.changes) === null || _b === void 0 ? void 0 : _b.toJSON();
                        // Only keep changes that give us non-trivial information
                        if (changes && annotations && (annotations.length > 0 || changes.length > 1 || typeof changes[0] !== "number")) {
                            appendToChangeLog({
                                changes: changes,
                                timestamp: Date.now(),
                                annotations: annotations,
                                selections: v.state.selection.toJSON().ranges
                            });
                        }
                    })
                ], false)
            }),
            parent: editorRef.current
        }));
    }, [initCode]);
    return _jsx("pre", { className: "editor", ref: editorRef });
});
