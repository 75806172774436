import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Editor } from "./Editor";
import { RunButtons } from "./RunButtons";
import { OutputTerminal, xtermInterface } from "./OutputTerminal";
import { useEffect, useRef, useState } from "react";
import { noop, tryCastString, useIFrameMessages } from "./services/utils";
import { DEMO_CODE_JS, DEMO_CODE_PYTHON, DEMO_SQL_QUERY, EXEC_STATE, IN_IFRAME, LANGUAGES, MESSAGE_TYPES } from "./constants";
import classNames from "classnames";
import { runQuery } from "./languages/sql";
import { OutputTable } from "./OutputTable";
import { Button } from "reactstrap";
var terminalInitialText = "Ada Code Editor - running Skulpt in xterm.js:\n";
var uid = window.location.hash.substring(1);
var handleRun = function (terminal, language, code, setupCode, testCode, wrapCodeInMain, printFeedback, shouldStopExecution, logSnapshot, onTestFinish, onSetupFail, doChecks) {
    // TODO handle when the errors throw are errors in code that content have written - they should be sent to the front-end
    var printError = function (_a) {
        var _b;
        var error = _a.error, isTestError = _a.isTestError, isContentError = _a.isContentError;
        printFeedback({
            succeeded: false,
            message: (_b = (isTestError ? error === null || error === void 0 ? void 0 : error.replace(/ on line \d+/, "") : error)) !== null && _b !== void 0 ? _b : "Undefined error (sorry, this particular code snippet may be broken)",
            isTest: isTestError
        });
        if (isTestError) {
            printFeedback({
                succeeded: false,
                message: "Your code failed at least one test!"
            });
        }
        else if (isContentError) {
            onSetupFail(error);
        }
    };
    // Reverses the inputs, importantly by returning a new array and not doing it in place with .reverse()
    var reversedInputs = [];
    var inputCount = 0;
    var outputRegex = undefined;
    var testInputHandler = function (sync) {
        // Every time "input()" is called, the first element of the test inputs is given as
        //  the user input, and that element is removed from the list. If no test input is
        //  available, a test error is thrown TODO add option for dummy inputs
        var asyncTestInputHandler = function () { return new Promise(function (resolve, reject) {
            var _a;
            inputCount -= 1;
            if (reversedInputs.length === 0) {
                reject({ error: "Your program asked for input when none was expected, so we couldn't give it a valid input...", isTestError: true });
            }
            else {
                // There is definitely an input here
                resolve((_a = reversedInputs.pop()) !== null && _a !== void 0 ? _a : "");
            }
        }); };
        var syncTestInputHandler = function () {
            var _a;
            inputCount -= 1;
            if (reversedInputs.length === 0) {
                throw { error: "Your program asked for input when none was expected, so we couldn't give it a valid input...", isTestError: true };
            }
            else {
                // There is definitely an input here
                return (_a = reversedInputs.pop()) !== null && _a !== void 0 ? _a : "";
            }
        };
        return function () {
            if (sync) {
                return syncTestInputHandler();
            }
            else {
                return asyncTestInputHandler();
            }
        };
    };
    var testCallbacks = {
        setTestInputs: function (inputs) {
            var _a;
            reversedInputs = (_a = inputs === null || inputs === void 0 ? void 0 : inputs.reduce(function (acc, x) { return [x].concat(acc); }, [])) !== null && _a !== void 0 ? _a : [];
            inputCount = reversedInputs.length;
        },
        setTestRegex: function (re) {
            outputRegex = re ? RegExp(re) : undefined;
        },
        runCurrentTest: function (currentOutput, allInputsMustBeUsed, successMessage, failMessage) {
            if (outputRegex) {
                //console.log(outputRegex);
                //console.log(currentOutput);
                if (!outputRegex.test(currentOutput)) {
                    // If the output does not match the provided regex
                    return { error: failMessage !== null && failMessage !== void 0 ? failMessage : "Your program produced unexpected output...", isTestError: true };
                }
                else if (undefined === successMessage) {
                    printFeedback({ succeeded: true, message: "The output of your program looks good", isTest: true });
                }
            }
            // Check whether all inputs were used (if needed)
            if (allInputsMustBeUsed) {
                if (inputCount > 0) {
                    // If the number of inputs used was not exactly the number provided, and the user had to use all available
                    //  test inputs, then this is an error
                    return { error: failMessage !== null && failMessage !== void 0 ? failMessage : "Your program didn't call input() enough times...", isTestError: true };
                }
                else if (inputCount < 0) {
                    return { error: failMessage !== null && failMessage !== void 0 ? failMessage : "Your program called input() too many times...", isTestError: true };
                }
                else if (undefined === successMessage) {
                    printFeedback({ succeeded: true, message: "Your program accepted the correct number of inputs", isTest: true });
                }
            }
            if (successMessage) {
                printFeedback({ succeeded: true, message: successMessage, isTest: true });
            }
            else if (!allInputsMustBeUsed && (undefined === outputRegex)) {
                printFeedback({ succeeded: true, message: "Test passed", isTest: true });
            }
            return undefined;
        }
    };
    // First clear the terminal
    terminal.clear();
    // If tests are being run, indicate this to the user
    if (doChecks) {
        // Green apple unicode: "\ud83c\udf4f"
        // Isaac CS banner: "\x1b[0m \x1b[1;44;30m    \u2b22     \x1b[0m"
        terminal.output("\x1b[1mRunning tests...\r\n");
    }
    var bundledSetupCode = language.testingLibrary + "\n" + (setupCode !== null && setupCode !== void 0 ? setupCode : "");
    if (language.requiresBundledCode) {
        var bundledCode = bundledSetupCode + "\n" + (wrapCodeInMain ? language.wrapInMain(code, doChecks) : code);
        if (doChecks) {
            var bundledTestCode = bundledCode + "\n" + testCode;
            return language.runTests("", testInputHandler(language.syncTestInputHander), shouldStopExecution, bundledTestCode, testCallbacks)
                .then(function (checkerResult) {
                onTestFinish(checkerResult);
            }).catch(printError);
        }
        else {
            return language.runCode(bundledCode, terminal.output, terminal.input, shouldStopExecution, { retainGlobals: true, execLimit: 30000 /* 30 seconds */ })
                .then(function (finalOutput) {
                logSnapshot({ snapshot: code, compiled: true, timestamp: Date.now() });
                return finalOutput;
            }).catch(function (e) {
                logSnapshot({ snapshot: code, compiled: false, timestamp: Date.now(), error: e });
                printError(e);
            });
        }
    }
    else {
        return language.runSetupCode(terminal.output, terminal.input, bundledSetupCode, testCallbacks)
            .catch(function (_a) {
            var error = _a.error;
            return onSetupFail(error);
        })
            .then(function () {
            // Wrap code in a 'main' function if specified by the content block
            var modifiedCode = wrapCodeInMain ? language.wrapInMain(code, doChecks) : code;
            return language.runCode(modifiedCode, doChecks ? noop : terminal.output, doChecks ? testInputHandler(language.syncTestInputHander) : terminal.input, shouldStopExecution, { retainGlobals: true, execLimit: 30000 /* 30 seconds */ });
        })
            .then(function (finalOutput) {
            logSnapshot({ snapshot: code, compiled: true, timestamp: Date.now() });
            // Run the tests only if the "Check" button was clicked
            if (doChecks) {
                return language.runTests(finalOutput, testInputHandler(language.syncTestInputHander), shouldStopExecution, testCode, testCallbacks)
                    .then(function (checkerResult) {
                    onTestFinish(checkerResult);
                });
            }
        })
            .catch(function (e) {
            logSnapshot({ snapshot: code, compiled: false, timestamp: Date.now(), error: e });
            printError(e);
        });
    }
};
export var Sandbox = function () {
    var _a = useState(!IN_IFRAME), loaded = _a[0], setLoaded = _a[1];
    var _b = useState(EXEC_STATE.STOPPED), running = _b[0], setRunning = _b[1];
    var _c = useState(IN_IFRAME ? {
        language: "python",
        code: "# Loading..."
    } : DEMO_CODE_PYTHON), predefinedCode = _c[0], setPredefinedCode = _c[1];
    var languageIsSQL = predefinedCode.language === "sql";
    var _d = useIFrameMessages(uid), receivedData = _d.receivedData, sendMessage = _d.sendMessage;
    var containerRef = useRef(null);
    var codeRef = useRef(null);
    var _e = useState(), xterm = _e[0], setXTerm = _e[1];
    var _f = useState(false), isFullscreen = _f[0], setIsFullscreen = _f[1];
    var _g = useState({ rows: [], columnNames: [] }), queryOutput = _g[0], setQueryOutput = _g[1];
    var _h = useState(false), recordLogs = _h[0], setRecordLogs = _h[1];
    var resizeObserverInitialised = useRef(false);
    var _j = useState(undefined), iFrameHeight = _j[0], setIFrameHeight = _j[1]; // readonly -- set by the parent
    var _k = useState([]), changeLog = _k[0], setChangeLog = _k[1];
    var appendToChangeLog = function (change) {
        if (!recordLogs)
            return;
        setChangeLog(function (current) { return (current.concat([change])); });
    };
    var _l = useState([]), snapshotLog = _l[0], setSnapshotLog = _l[1];
    var appendToSnapshotLog = function (snapshot) {
        if (!recordLogs)
            return;
        setSnapshotLog(function (current) { return (current.concat([snapshot])); });
    };
    // Create a resize observer to update the height of the iframe when containerRef.current changes size
    useEffect(function () {
        if (!containerRef.current || !loaded)
            return;
        var resizeObserver = new ResizeObserver(function () {
            // wrapping this in a requestAnimationFrame ignores a benign warning about ResizeObserver not finishing in one frame: https://stackoverflow.com/a/50387233
            window.requestAnimationFrame(function () {
                var _a, _b, _c;
                if (!resizeObserverInitialised.current) {
                    // the first resize event is caused by the ResizeObserver being initialised, not a change in size.
                    // however, this is the first time we have access to the container's height, so we can 
                    // calculate and set the code editor's height here relative to the iframe's height.
                    resizeObserverInitialised.current = true;
                    (_a = codeRef.current) === null || _a === void 0 ? void 0 : _a.setHeight(iFrameHeight !== null && iFrameHeight !== void 0 ? iFrameHeight : 250);
                    return;
                }
                sendMessage({
                    type: MESSAGE_TYPES.RESIZE,
                    height: (_c = (_b = containerRef.current) === null || _b === void 0 ? void 0 : _b.scrollHeight) !== null && _c !== void 0 ? _c : 0
                });
            });
        });
        resizeObserver.observe(containerRef.current);
        return function () {
            resizeObserverInitialised.current = false;
            resizeObserver.disconnect();
        };
    }, [loaded]);
    var shouldStop = useRef(false);
    // Called by the code execution handling functions to check whether they should stop executing.
    var shouldStopExecution = function (stop) {
        if (!stop)
            return shouldStop.current;
        if (shouldStop.current) {
            shouldStop.current = false;
            return true;
        }
        return false;
    };
    // To be called by either the stop button or inside the MESSAGE_TYPES.INITIALISE message receive code. It sets
    // a flag which the running code periodically checks to see whether it should stop execution.
    var stopExecution = function () {
        var _a, _b;
        shouldStop.current = true;
        // This is horrible... dispatch a random key event to xterm so that it "realises" that it should stop accepting input
        (_b = (_a = xterm === null || xterm === void 0 ? void 0 : xterm.element) === null || _a === void 0 ? void 0 : _a.querySelector(".xterm-helper-textarea")) === null || _b === void 0 ? void 0 : _b.dispatchEvent(new KeyboardEvent('keydown', {
            key: "b",
            keyCode: 66,
            code: "KeyE",
            which: 66,
            shiftKey: false,
            ctrlKey: false,
            metaKey: false
        }));
        return;
    };
    useEffect(function () {
        var _a, _b;
        if (undefined === receivedData)
            return;
        /** The editor can receive two types of messages
         * Initial messages, used to pass the initial code in the editor and the test to perform
         * {
         *     type: "initialise",
         *     code: "# Calculate the area of a circle below!\ndef circleArea(radius):",
         *     setup: "pi = 3.142"
         *     test: "checkerResult = str([circleArea(2), circleArea(8), circleArea(1), circleArea(-3)])"
         * }
         *
         * Feedback messages, to indicate whether the student was correct or not
         * {
         *     type: "feedback",
         *     succeeded: true,
         *     message: "Congratulations, you passed the test!"
         * }
         */
        if (receivedData.type === MESSAGE_TYPES.INITIALISE) {
            // Stop currently running code (or try to)
            if (running !== EXEC_STATE.STOPPED) {
                stopExecution();
            }
            var newPredefCode = {
                setup: (_a = tryCastString(receivedData === null || receivedData === void 0 ? void 0 : receivedData.setup)) !== null && _a !== void 0 ? _a : "",
                code: tryCastString(receivedData === null || receivedData === void 0 ? void 0 : receivedData.code),
                wrapCodeInMain: (receivedData === null || receivedData === void 0 ? void 0 : receivedData.wrapCodeInMain) ? receivedData === null || receivedData === void 0 ? void 0 : receivedData.wrapCodeInMain : undefined,
                test: tryCastString(receivedData === null || receivedData === void 0 ? void 0 : receivedData.test),
                dataUrl: tryCastString(receivedData === null || receivedData === void 0 ? void 0 : receivedData.dataUrl),
                language: tryCastString(receivedData === null || receivedData === void 0 ? void 0 : receivedData.language)
            };
            setRecordLogs((receivedData === null || receivedData === void 0 ? void 0 : receivedData.logChanges) ? receivedData === null || receivedData === void 0 ? void 0 : receivedData.logChanges : false);
            setPredefinedCode(newPredefCode);
            setIsFullscreen((receivedData === null || receivedData === void 0 ? void 0 : receivedData.fullscreen) ? receivedData === null || receivedData === void 0 ? void 0 : receivedData.fullscreen : false);
            setIFrameHeight(receivedData === null || receivedData === void 0 ? void 0 : receivedData.iFrameHeight);
            setLoaded(true);
            // Clear any irrelevant log data, and make an initial snapshot
            setChangeLog([]);
            setSnapshotLog([{ compiled: false, snapshot: (_b = newPredefCode.code) !== null && _b !== void 0 ? _b : "", timestamp: Date.now() }]);
            // Clear any old terminal and table output
            xterm && xtermInterface(xterm, function () { return shouldStopExecution(false); }).clear();
            setQueryOutput({ rows: [], columnNames: [] });
            // Confirm that the initialisation was successful
            sendMessage({
                type: MESSAGE_TYPES.CONFIRM_INITIALISED,
            });
        }
        else if (receivedData.type === MESSAGE_TYPES.FEEDBACK) {
            printFeedback({
                succeeded: receivedData.succeeded,
                message: receivedData.message
            });
        }
        else if (receivedData.type === MESSAGE_TYPES.PING) {
            if (containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) {
                sendMessage({
                    type: MESSAGE_TYPES.PING,
                    timestamp: Date.now()
                });
            }
        }
        else if (receivedData.type === MESSAGE_TYPES.LOGS) {
            if (containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) {
                sendMessage({
                    type: MESSAGE_TYPES.LOGS,
                    changes: changeLog,
                    snapshots: snapshotLog
                });
                setChangeLog([]);
                setSnapshotLog([]);
            }
        }
    }, [receivedData]);
    var sendCheckerResult = function (checkerResult) {
        sendMessage({ type: MESSAGE_TYPES.CHECKER, result: checkerResult });
    };
    var alertSetupCodeFail = function (error) {
        console.log("Setup code failed with error: " + error);
        sendMessage({ type: MESSAGE_TYPES.SETUP_FAIL, message: error });
    };
    // Dependant on xterm character encoding - will need changing for a different terminal
    var printFeedback = function (_a) {
        var succeeded = _a.succeeded, message = _a.message, isTest = _a.isTest;
        xterm && xtermInterface(xterm, function () { return shouldStopExecution(true); }).output("\u001B[".concat(succeeded ? "32" : "31", ";1m") + (isTest ? "> " : "") + message + (succeeded && isTest ? " \u2714" : "") + "\x1b[0m\r\n");
    };
    // The main entry point for running code. It is called by the run button.
    var callHandleRun = function (doChecks) { return function () {
        var _a, _b, _c;
        if (!loaded || !xterm)
            return;
        if (running !== EXEC_STATE.STOPPED) {
            stopExecution();
            return;
        }
        shouldStop.current = false;
        if ((predefinedCode === null || predefinedCode === void 0 ? void 0 : predefinedCode.language) === "sql") {
            setRunning(EXEC_STATE.RUNNING);
            var editorCode = ((_a = codeRef === null || codeRef === void 0 ? void 0 : codeRef.current) === null || _a === void 0 ? void 0 : _a.getCode()) || "";
            runQuery(editorCode, predefinedCode.dataUrl)
                .then(function (_a) {
                var rows = _a.rows, columnNames = _a.columnNames, changes = _a.changes;
                var message = rows.length === 0
                    ? "Query succeeded, ".concat(changes, " row").concat(changes === 1 ? "" : "s", " affected")
                    : "Query returned ".concat(rows.length, " row").concat(rows.length === 1 ? "" : "s");
                setQueryOutput({ rows: rows, columnNames: columnNames, message: message });
            }).catch(function (e) {
                setQueryOutput({ rows: [], error: e.toString(), columnNames: [] });
            }).then(function () { return setRunning(EXEC_STATE.STOPPED); });
            return;
        }
        var language = LANGUAGES.get((_b = predefinedCode === null || predefinedCode === void 0 ? void 0 : predefinedCode.language) !== null && _b !== void 0 ? _b : "");
        if (language) {
            setRunning(doChecks ? EXEC_STATE.CHECKING : EXEC_STATE.RUNNING);
            var editorCode = ((_c = codeRef === null || codeRef === void 0 ? void 0 : codeRef.current) === null || _c === void 0 ? void 0 : _c.getCode()) || "";
            handleRun(xtermInterface(xterm, function () { return shouldStopExecution(true); }), language, editorCode, predefinedCode.setup, predefinedCode.test, predefinedCode.wrapCodeInMain, printFeedback, shouldStopExecution, appendToSnapshotLog, sendCheckerResult, alertSetupCodeFail, doChecks)
                .then(function () { return setRunning(EXEC_STATE.STOPPED); });
        }
        else {
            alertSetupCodeFail("Unknown programming language - unable to run code!");
        }
    }; };
    // Only used in the demo
    var cycleCodeSnippet = function () {
        if (!loaded)
            return;
        if ((predefinedCode === null || predefinedCode === void 0 ? void 0 : predefinedCode.language) === "sql") {
            setPredefinedCode(DEMO_CODE_PYTHON);
        }
        else if ((predefinedCode === null || predefinedCode === void 0 ? void 0 : predefinedCode.language) === "python") {
            setPredefinedCode(DEMO_CODE_JS);
        }
        else {
            setPredefinedCode(DEMO_SQL_QUERY);
        }
    };
    return _jsxs("div", { ref: containerRef, className: classNames({ "m-5": !IN_IFRAME }), children: [!IN_IFRAME && _jsxs(_Fragment, { children: [_jsxs("h2", { children: ["Ada Code Editor Demo   ", _jsx(Button, { size: "sm", className: "d-inline-block", color: "outline", onClick: cycleCodeSnippet, children: "Cycle code snippet" })] }), languageIsSQL
                        ? _jsx(_Fragment, { children: _jsxs("p", { children: ["Here is an example of a SQLite query! Interact with the query to understand how it works.", _jsx("br", {}), "The tables you have access to are listed below:", _jsxs("ul", { children: [_jsx("li", { children: _jsx("code", { children: "Member" }) }), _jsx("li", { children: _jsx("code", { children: "Course" }) }), _jsx("li", { children: _jsx("code", { children: "Instructor" }) }), _jsx("li", { children: _jsx("code", { children: "Certificate" }) })] })] }) })
                        : _jsxs(_Fragment, { children: [_jsxs("p", { children: ["Below is an implementation of the bubble sort algorithm! It is an example of ", _jsx("b", { children: "indefinite" }), " and ", _jsx("b", { children: "nested" }), " iteration. Interact with the code to understand how it works."] }), _jsx("p", { children: "If you modify the code, you can press the test button to see if it still sorts lists correctly." })] })] }), _jsx(Editor, { initCode: predefinedCode.code, language: predefinedCode.language, ref: codeRef, appendToChangeLog: appendToChangeLog }), _jsx(RunButtons, { running: running, loaded: loaded, onRun: callHandleRun(false), onCheck: callHandleRun(true), showCheckButton: !!("test" in predefinedCode && predefinedCode.test) }), _jsx(OutputTerminal, { setXTerm: setXTerm, hidden: languageIsSQL }), languageIsSQL && _jsx(OutputTable, { rows: queryOutput.rows, error: queryOutput.error, columnNames: queryOutput.columnNames, message: queryOutput.message, fullscreen: isFullscreen })] });
};
