import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { Terminal } from "@xterm/xterm";
import { FitAddon } from "@xterm/addon-fit";
import { WebglAddon } from "@xterm/addon-webgl";
import { ERRORS } from "./constants";
/**
 * Handling a single input character to the xterm terminal - will recurse, building up a string
 * output, until CRLF is input.
 */
var handleSingleInputChar = function (xterm, input, checkExecutionStopped) { return new Promise(function (resolve, reject) {
    if (undefined === xterm || checkExecutionStopped()) {
        return reject(ERRORS.EXEC_STOP_ERROR);
    }
    var onDataListener = xterm.onData(function (s) {
        if (checkExecutionStopped()) {
            onDataListener.dispose();
            reject(ERRORS.EXEC_STOP_ERROR);
            return;
        }
        // This does the recursive call by cleaning up the old listener and handling the next input
        var cleanUpAndRecurse = function (newInput) {
            onDataListener.dispose();
            handleSingleInputChar(xterm, newInput, checkExecutionStopped).then(resolve).catch(reject);
        };
        switch (s) {
            case '\u0003': // Ctrl+C
                var xtermSelection_1 = xterm.getSelection();
                navigator.clipboard.writeText(xtermSelection_1).then(function () {
                    console.log("Copied: ".concat(xtermSelection_1, " to clipboard!"));
                }).catch(function () {
                    console.log("Failed to copy selection to clipboard");
                }); // Could make this show a "copied to clipboard" popup?
                cleanUpAndRecurse(input);
                return;
            case '\u0016': // Ctrl+V
                navigator.clipboard.readText().then(function (s) {
                    cleanUpAndRecurse(input + s);
                    xterm.write(s);
                }).catch(function () {
                    cleanUpAndRecurse(input);
                });
                onDataListener.dispose();
                return;
            case '\r': // Enter
                onDataListener.dispose();
                xterm.write("\r\n");
                resolve(input);
                return;
            case '\n': // Enter (don't handle second character generated)
                return;
            case '\u007F': // Backspace (DEL)
                // Do not delete the prompt
                if (input.length > 0) {
                    xterm.write('\b \b');
                    cleanUpAndRecurse(input.slice(0, -1));
                    return;
                }
                break;
            default: // Print all other characters for demo
                if (s >= String.fromCharCode(0x20) && s <= String.fromCharCode(0x7B) || s >= '\u00a0') {
                    xterm.write(s);
                    cleanUpAndRecurse(input + s);
                    return;
                }
        }
        cleanUpAndRecurse(input);
        return;
    });
}); };
export var xtermInterface = function (xterm, checkExecutionStopped) { return ({
    input: function () { return handleSingleInputChar(xterm, "", checkExecutionStopped); },
    output: function (output) { return xterm.write(output.replace(/\n/g, "\r\n")); },
    clear: function () {
        xterm.write('\x1bc');
        xterm.clear();
    }
}); };
var baseTheme = {
    foreground: '#F8F8F8',
    background: '#000000',
    selection: '#FFB53F33',
    black: '#1E1E1D',
    brightBlack: '#262625',
    red: '#CE5C5C',
    brightRed: '#FF7272',
    green: '#5BCC5B',
    brightGreen: '#72FF72',
    yellow: '#CCCC5B',
    brightYellow: '#FFFF72',
    blue: '#e0a851',
    brightBlue: '#ffb53f',
    // blue: '#5D5DD3',
    // brightBlue: '#7279FF',
    magenta: '#BC5ED1',
    brightMagenta: '#E572FF',
    cyan: '#5DA5D5',
    brightCyan: '#72F0FF',
    white: '#F8F8F8',
    brightWhite: '#FFFFFF'
};
export var OutputTerminal = function (_a) {
    var setXTerm = _a.setXTerm, hidden = _a.hidden;
    var xtermDiv = useRef(null);
    useEffect(function () {
        if (null === xtermDiv.current)
            return;
        var newTerm = new Terminal({
            fontFamily: "Consolas, monospace",
            theme: baseTheme,
            cursorBlink: true
        });
        setXTerm(newTerm);
        newTerm.open(xtermDiv.current);
        var isWebglEnabled = false;
        try {
            newTerm.loadAddon(new WebglAddon());
            isWebglEnabled = true;
        }
        catch (e) {
            console.warn("WebGL addon threw an exception during load", e);
        }
        var fitAddon = new FitAddon();
        newTerm.loadAddon(fitAddon);
        fitAddon.fit();
        var fit = function () {
            fitAddon.fit();
        };
        window.addEventListener("resize", fit);
        // Allow scrolling inside the terminal to not scroll the outer window
        var stopScrollProp = function (e) {
            e.preventDefault();
        };
        xtermDiv.current.addEventListener("wheel", stopScrollProp);
        xtermDiv.current.addEventListener("scroll", stopScrollProp);
        newTerm.attachCustomKeyEventHandler(function (e) {
            // Don't process the key if it is ctrl-c (or cmd-c on Mac)
            // This means that the copy key event doesn't clear the terminal selection
            return !((e.ctrlKey || e.metaKey) && e.key === "c");
        });
        return function () {
            var _a, _b;
            newTerm.dispose();
            window.removeEventListener("resize", fit);
            (_a = xtermDiv === null || xtermDiv === void 0 ? void 0 : xtermDiv.current) === null || _a === void 0 ? void 0 : _a.removeEventListener("wheel", stopScrollProp);
            (_b = xtermDiv === null || xtermDiv === void 0 ? void 0 : xtermDiv.current) === null || _b === void 0 ? void 0 : _b.removeEventListener("scroll", stopScrollProp);
        };
    }, []);
    return _jsx("div", { hidden: hidden, className: "output-terminal-container", children: _jsx("div", { id: "output-terminal", className: "w-100 h-100", ref: xtermDiv }) });
};
