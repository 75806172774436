var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { EditorView } from "codemirror";
import { HighlightStyle, syntaxHighlighting } from "@codemirror/language";
import { tags } from "@lezer/highlight";
import { StandardSQL } from "@codemirror/lang-sql";
import { LanguageSupport } from "@codemirror/language";
var log = console.log;
var error = console.error;
var sqlite3;
var db;
var currentDbLink;
// system queries
var QUERIES = {
    version: "select sqlite_version() as version",
    tables: "select name as \"table\" from sqlite_schema\n      where type = 'table'\n        and name not like 'sqlite_%'\n        and name not like 'sqlean_%'",
    foreignKeysOn: "PRAGMA foreign_keys = ON"
};
var setupSqlite3 = function () { return __awaiter(void 0, void 0, void 0, function () {
    var sqlite3InitModule;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, import("@sqlite.org/sqlite-wasm")];
            case 1:
                sqlite3InitModule = (_a.sent()).default;
                return [4 /*yield*/, sqlite3InitModule({
                        print: log,
                        printErr: error,
                    }).then(function (sql3) {
                        try {
                            log('Running SQLite3 version', sql3.version.libVersion);
                            sqlite3 = sql3;
                            return sqlite3;
                        }
                        catch (err) {
                            // @ts-ignore
                            error(err.name, err.message);
                        }
                    })];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); };
var downloadDatabase = function (sqlite3, link) {
    return fetch(link)
        .then(function (response) { return response.arrayBuffer(); })
        .then(function (arrayBuffer) {
        var p = sqlite3.wasm.allocFromTypedArray(arrayBuffer);
        var db = new sqlite3.oo1.DB();
        var rc = sqlite3.capi.sqlite3_deserialize(db.pointer, 'main', p, arrayBuffer.byteLength, arrayBuffer.byteLength, sqlite3.capi.SQLITE_DESERIALIZE_FREEONCLOSE);
        db.checkRc(rc);
        return db;
    }).catch(function (err) {
        console.error(err);
        return new sqlite3.oo1.DB('/mydb.sqlite3', 'ct');
    });
};
export var runQuery = function (query_1) {
    var args_1 = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args_1[_i - 1] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([query_1], args_1, true), void 0, function (query, link) {
        var rows, columnNames, changes;
        if (link === void 0) { link = "NO_DB"; }
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!!sqlite3) return [3 /*break*/, 2];
                    return [4 /*yield*/, setupSqlite3()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    if (!(currentDbLink !== link)) return [3 /*break*/, 6];
                    if (!(link !== "NO_DB")) return [3 /*break*/, 4];
                    return [4 /*yield*/, downloadDatabase(sqlite3, link)];
                case 3:
                    db = _a.sent();
                    return [3 /*break*/, 5];
                case 4:
                    db = new sqlite3.oo1.DB('/mydb.sqlite3', 'ct');
                    _a.label = 5;
                case 5:
                    db.exec(QUERIES.foreignKeysOn);
                    currentDbLink = link;
                    _a.label = 6;
                case 6:
                    rows = [];
                    columnNames = [];
                    // db.exec({
                    //     sql: QUERIES.version,
                    //     rowMode: "array",
                    //     // @ts-ignore
                    //     resultRows: rows,
                    // });
                    // console.log('Version:', rows);
                    // rows = [];
                    // db.exec({
                    //     sql: QUERIES.tables,
                    //     rowMode: "array",
                    //     // @ts-ignore
                    //     resultRows: rows,
                    // });
                    // console.log('Tables:', rows);
                    // rows = [];
                    db.exec({
                        sql: query,
                        rowMode: "array",
                        // @ts-ignore
                        resultRows: rows,
                        columnNames: columnNames,
                    });
                    changes = db.changes();
                    return [2 /*return*/, { rows: rows, columnNames: columnNames, changes: changes }];
            }
        });
    });
};
export var sqlTheme = EditorView.theme({
    ".cm-content": {
        minHeight: "10ex"
    },
    ".cm-gutters": {
        backgroundColor: "#FFFFFF"
    },
    ".cm-line": {
        paddingLeft: "7px"
    },
    "&": {
        color: "#545454"
    },
});
export var sqlHighlightStyle = HighlightStyle.define([
    { tag: tags.docString, color: "#008000" },
    { tag: tags.comment, color: "#696969" },
    { tag: tags.definitionKeyword, color: "#007faa" },
    { tag: tags.function(tags.definition(tags.variableName)), color: "#007faa" },
    { tag: tags.keyword, color: "#7928a1" },
    { tag: tags.number, color: "#aa5d00" },
    { tag: tags.bool, color: "#aa5d00" },
    { tag: tags.lineComment, color: "#696969" },
    { tag: tags.string, color: "#008000" },
]);
export var sqlCodeMirrorTheme = {
    languageSupport: new LanguageSupport(StandardSQL.language, []),
    theme: sqlTheme,
    highlightStyle: syntaxHighlighting(sqlHighlightStyle),
};
