import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Table } from "reactstrap";
import classNames from "classnames";
export var OutputTable = function (_a) {
    var rows = _a.rows, columnNames = _a.columnNames, error = _a.error, message = _a.message, fullscreen = _a.fullscreen;
    return _jsxs(_Fragment, { children: [message && _jsx("div", { className: "sql-message", children: message }), _jsx("div", { className: classNames("output-table-container", { "no-message": !message && !fullscreen, "fullscreen": fullscreen }), children: error
                    ? _jsx("div", { className: "sql-error", children: error })
                    : _jsxs(Table, { children: [_jsx("thead", { children: _jsx("tr", { children: columnNames.map(function (c, i) {
                                        return _jsx("th", { children: c }, i);
                                    }) }) }), _jsx("tbody", { children: rows.map(function (r, i) {
                                    return _jsx("tr", { className: i % 2 ? "bg-light" : "bg-dark", children: r.map(function (c, j) {
                                            return _jsx("td", { children: c }, j);
                                        }) }, i);
                                }) })] }) })] });
};
